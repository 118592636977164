import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import React, { ReactElement, ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  section: {
    margin: 0,
  },
  bottomLine: {
    height: 5,
    backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main} , ${theme.palette.primary.main})`,
  },
}));

interface SectionProps {
  children: ReactNode,
}

const Header = (
  {
    children,
  }: SectionProps,
): ReactElement => {
  const classes = useStyles();
  return (
    <section>
      {children}
      <div className={classes.bottomLine} />
    </section>
  );
};

export default Header;
