import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import MLogo from './MLogo';
import MLogoWhite from './MLogoWhite';
import { ImageType } from '../interfaces';

const useStyles = makeStyles((theme: Theme) => createStyles({
  section: {
    margin: 0,
    position: 'relative',
    overflow: 'hidden',
  },
  white: {
    backgroundColor: '#fff',
  },
  light: {
    backgroundColor: theme.palette.grey['100'],
  },
  lightGradiant: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.grey['100']}, ${theme.palette.grey['200']})`,
  },
  lightPrimary: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.grey['100']} 55%, ${theme.palette.primary.light})`,
  },
  primaryLight: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.grey['100']} 35%)`,
  },
  secondaryLight: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.light}, ${theme.palette.grey['100']} 35%)`,
  },
  darkPrimary: {
    backgroundImage: `linear-gradient(to right, ${theme.dark.main} 15%, ${theme.palette.primary.light})`,
    color: theme.palette.background.paper,
  },
  dark: {
    backgroundColor: theme.dark.main,
    color: theme.palette.background.paper,
  },
  image: {
    position: 'absolute',
    height: '100%',
    top: 0,
    opacity: 0.95,
    mixBlendMode: 'overlay',
  },
  imageLeft: {
    maskImage: 'linear-gradient(to left, transparent 5%, black 55%);',
    left: 0,
  },
  imageRight: {
    maskImage: 'linear-gradient(to right, transparent 5%, black 55%);',
    right: 0,
  },
  logo: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '100%',
    zIndex: 1,
  },
  logoRight: {
    right: -225,
    maskImage: 'linear-gradient(to right, transparent 5%, black 55%);',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      bottom: 0,
      height: '50%',
      right: -75,
      transform: 'unset',
    },
  },
  logoLeft: {
    left: -225,
    maskImage: 'linear-gradient(to left, transparent 5%, black 55%);',
  },
  logoWhite: {
    opacity: 0.30,
    mixBlendMode: 'screen',
    maskImage: 'unset',
  },
}));

interface SectionProps extends ComponentProps<any> {
  children: ReactNode,
  background?: 'white' | 'light' | 'lightGradiant' | 'dark' | 'lightPrimary' | 'primaryLight' | 'secondaryLight' | 'darkPrimary',
  mlogoRight?: boolean,
  mlogoLeft?: boolean,
  mlogoWhite?: boolean,
  image?: ImageType,
  imageLeft?: boolean,
}

const Section = (
  {
    children,
    background,
    mlogoRight,
    mlogoLeft,
    mlogoWhite,
    image,
    imageLeft,
  }: SectionProps,
): ReactElement => {
  const classes = useStyles();
  return (
    <section className={`${classes.section} ${background && classes[background]}`}>
      {children}
      {mlogoRight && (mlogoWhite
        ? <MLogoWhite className={`${classes.logo} ${classes.logoRight} ${classes.logoWhite}`} />
        : <MLogo className={`${classes.logo} ${classes.logoRight}`} />)}
      {mlogoLeft && (mlogoWhite
        ? <MLogoWhite className={`${classes.logo} ${classes.logoLeft} ${classes.logoWhite}`} />
        : <MLogo className={`${classes.logo} ${classes.logoLeft}`} />)}
      {image && <img src={image.src} alt={image.alt} className={`${classes.image} ${imageLeft ? classes.imageLeft : classes.imageRight}`} />}
    </section>
  );
};

Section.defaultProps = {
  background: 'white',
  mlogoRight: undefined,
  mlogoLeft: undefined,
  mlogoWhite: false,
  image: undefined,
  imageLeft: false,
};

export default Section;
