import React, { FC, ReactElement } from 'react';
import {
  MuiThemeProvider as ThemeProvider, responsiveFontSizes,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import RouteItem from './interfaces';
import { routes } from './config';
import createTheme from './styles/themes';

const DefaultComponent: FC<{}> = (): ReactElement => (
  <div>No Component Defined.</div>
);

function App() {
  // const [useDefaultTheme, toggle] = useReducer((theme) => !theme, true);

  let theme: Theme = createTheme;
  theme = responsiveFontSizes(theme);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router forceRefresh={false}>
          <Layout>
            <Switch>
              {routes.map((route: RouteItem) => (
                route.subRoutes && !route.singlePage && !route.subRouteFooterOnly
                && route.subRoutes.length > 0
                  ? route.subRoutes.map((subRoute: RouteItem) => (
                    <Route
                      key={`${subRoute.key}`}
                      path={`${subRoute.path}`}
                      component={subRoute.component || DefaultComponent}
                      exact
                    />
                  ))
                  : (
                    <Route
                      key={`${route.key}`}
                      path={`${route.path}`}
                      component={route.component || DefaultComponent}
                      exact
                    />
                  )
              ))}
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
