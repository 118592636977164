import React from 'react';
import {
  Box, createStyles, Grid, makeStyles, Typography, Link as MuiLink, Container as MuiContainer,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MLogo from './MLogo';
import Container from './Container';
import { routes } from '../config';

const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    backgroundColor: theme.dark.main,
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    '& a': {
      fontSize: '0.7rem',
      color: '#fff',
      marginBottom: 3,
      '&:last-of-type': {
        marginBottom: 'unset',
      },
    },
  },
  logo: {
    position: 'absolute',
    bottom: 0,
    left: -165,
    height: '150%',
    maskImage: 'linear-gradient(to left, transparent 15%, black 120%)',
  },
  menu: {
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(3),
  },
  nonLink: {
    fontSize: '0.7rem',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Box py={3}>
          <Grid container>
            <Grid item xs={12} sm={3} md={5} />
            <Grid item xs={12} sm={9} md={7}>
              <Box display="flex" className={classes.menu} justifyContent="space-between">
                {routes.map((route) => (
                  route.enabled && route.menuItem && (
                    <React.Fragment key={route.key}>
                      <Box display="flex" flexDirection="column">
                        {route.subRoutes && route.subRoutes.length > 0 ? (
                          <React.Fragment key={route.key}>
                            <Typography variant="subtitle2">{route.title}</Typography>
                            <Box display="flex" flexDirection="column" mt={2}>
                              {route.subRoutes && route.subRoutes.map((subRoute) => (
                                subRoute.key === 'router-contact-sub'
                                  ? (
                                    <React.Fragment key={subRoute.key}>
                                      <Typography
                                        className={classes.nonLink}
                                      >
                                        403-919-6203
                                      </Typography>
                                      <Typography
                                        className={classes.nonLink}
                                      >
                                        806 - 7015 MacLeod Trail SW,
                                      </Typography>
                                      <Typography
                                        className={classes.nonLink}
                                      >
                                        Calgary, AB, T2H 2K6
                                      </Typography>
                                    </React.Fragment>
                                  )
                                  : (
                                    <Link
                                      to={subRoute.path}
                                      component={MuiLink}
                                      key={subRoute.key}
                                    >
                                      {subRoute.title}
                                    </Link>
                                  )
                              ))}
                            </Box>
                          </React.Fragment>
                        ) : (
                          <Typography variant="subtitle2">{route.title}</Typography>
                        )}
                      </Box>
                    </React.Fragment>
                  )
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <MuiContainer maxWidth="md" style={{ position: 'relative', zIndex: 5 }}>
        <Typography variant="caption" style={{ textTransform: 'uppercase' }}>&copy; 2022 | MOT&#362;S Consult Ltd.</Typography>
      </MuiContainer>
      <MLogo className={classes.logo} />
    </footer>
  );
};

export default Footer;
