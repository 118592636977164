import {
  Box, createStyles, Divider, Grid, List, ListItem, ListItemText, makeStyles, Typography,
} from '@material-ui/core';
import { Adjust } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import React, { ReactElement, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Button from './Button';
import Container from './Container';
import { ImageType } from '../interfaces';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CarouselContext } from '../contexts/contexts';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    paddingLeft: 0,
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: '1s ease-in',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#fff',
    '&:hover': {
      opacity: 1,
    },
    padding: theme.spacing(4),
    '> *': {
      margin: theme.spacing(1),
    },
    zIndex: 2,
  },
  rootContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse ! important',
    },
  },
  imageContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    color: '#fff',
    '& .carousel-root': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& .carousel-slider': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& .slider-wrapper': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '& .slider': {
            flex: 1,
          },
        },
      },
    },
    '& .slide': {
      backgroundColor: theme.palette.grey.A100,
      textAlign: 'left',
      display: 'flex',
    },
    '& .carousel .control-dots': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      paddingBottom: theme.spacing(0.5),
      paddingTop: '1px',
    },
    '& .carousel .control-dots .dot': {
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.25)',
    },
    '& .carousel .slide img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minHeight: '100%',
      width: 'unset',
      // minWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(50),
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // transform: 'translate(-50%, -50%)',
    // maxWidth: '100%',
    // maxHeight: '100%',
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  imageRight: {
    flexDirection: 'row-reverse',
  },
  imageLeft: {
    flexDirection: 'row',
  },
  anchor: {
    fontSize: '0.7rem',
    marginBottom: theme.spacing(1),
    display: 'block',
    color: theme.palette.grey.A200,
    border: 0,
    outline: 0,
  },
  overlayDetails: {
    fontSize: '0.9rem',
  },
}));

interface ButtonContainerProps {
  to?: string,
  title?: string,
  href?: string,
}

const ButtonContainer = (
  {
    to,
    title,
    href,
  }: ButtonContainerProps,
): ReactElement => (
  <Box display="flex" justifyContent="center" mt={4}>
    {to ? (
      <Link
        component={Button}
        to={to}
      >
        {title}
      </Link>
    ) : (
      <Button
        href={href}
        {...{ download: true }}
      >
        {title}
      </Button>
    )}
  </Box>
);

ButtonContainer.defaultProps = {
  title: 'Read more',
  href: '',
  to: '',
};

interface ImageTextContainerProps {
  heading: string;
  body: (string | React.ReactNode)[];
  images: ImageType[];
  buttonLocation?: 'text' | 'image';
  to?: string,
  imageRight?: boolean,
  href?: string,
  list?: string[],
  listTitle?: string,
  buttonTitle?: string,
  minHeight?: number,
  anchor?: string,
  showIndicators?: boolean,
}

const ImageTextContainer = (
  {
    heading,
    body = [],
    images,
    buttonLocation,
    to,
    imageRight,
    href,
    list,
    listTitle,
    buttonTitle,
    minHeight,
    anchor,
    showIndicators,
  }: ImageTextContainerProps,
): ReactElement => {
  const classes = useStyles();
  const { autoPlay, setAutoPlay } = useContext(CarouselContext);
  const randIntervals = [3000, 3100, 2900];
  return (
    <Container>
      <Grid
        container
        spacing={8}
        className={`${classes.rootContainer} ${imageRight ? classes.imageRight : classes.imageLeft}`}
      >
        <Grid item xs={12} sm={6}>
          <Box className={classes.imageContainer} minHeight={minHeight}>
            <Carousel
              showThumbs={false}
              showArrows={false}
              autoPlay={autoPlay}
              infiniteLoop
              showStatus={false}
              showIndicators={showIndicators}
              interval={randIntervals[Math.floor(Math.random() * randIntervals.length)]}
            >
              {images.map((image, index) => (
                <Box
                  key={image.key ?? index}
                  flex="1 1"
                  display="flex"
                  alignItems="center"
                  position="relative"
                  overflow="hidden"
                  onMouseEnter={() => setAutoPlay(false)}
                  onMouseLeave={() => setAutoPlay(true)}
                >
                  <img src={image.src} alt={image.alt} />
                  {image.title && (
                  <>
                    <Box
                      className={classes.imageOverlay}
                    >
                      <Box mb={2}>
                        <Typography variant="h4">{image.title}</Typography>
                      </Box>
                      {image.details && (
                        <Box>
                          <Typography className={classes.overlayDetails} variant="body1">{image.details}</Typography>
                        </Box>
                      )}
                      {image.credit && (
                        <>
                          <Box my={2} maxWidth={100}>
                            <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
                          </Box>
                          <Box>
                            <Typography variant="caption">{image.credit}</Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box position="absolute" bottom={9} right={10} zIndex={20}>
                      <Adjust fontSize="small" />
                    </Box>
                  </>
                  )}
                </Box>
              ))}
            </Carousel>
            {(to || href) && buttonLocation === 'image' && (
              <ButtonContainer
                to={to}
                title={buttonTitle}
                href={href}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          {anchor && (
            <a className={classes.anchor} href={`#${anchor}`} id={anchor}>
              #
              {anchor}
            </a>
          )}
          {heading && <Typography variant="h2">{heading}</Typography>}
          {/* eslint-disable-next-line react/no-array-index-key */}
          {body.map((paragraph, index) => <Typography variant="body2" align="justify" key={index}>{paragraph}</Typography>)}
          {list && (
            <Box mt={2}>
              {listTitle && <Typography variant="h5">{listTitle}</Typography>}
              <List dense disablePadding>
                {list.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem classes={{ root: classes.root }} key={index}>
                    <ListItemText
                      primary={item}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {(to || href) && buttonLocation === 'text' && (
            <ButtonContainer
              href={href}
              to={to}
              title={buttonTitle}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

ImageTextContainer.defaultProps = {
  to: undefined,
  buttonLocation: 'text',
  imageRight: false,
  href: '',
  list: [],
  listTitle: undefined,
  buttonTitle: 'Read More',
  minHeight: undefined,
  anchor: undefined,
  showIndicators: false,
};

export default ImageTextContainer;
