import React, { ReactElement } from 'react';
import {
  Avatar, Box,
  createStyles, Grid, makeStyles, Typography,
  /* ImageList, ImageListItem, ImageListItemBar */
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  Apartment, Build, Settings,
} from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link';
import Section from '../components/Section';
import Container from '../components/Container';
import Button from '../components/Button';
import Line from '../components/Line';
import ImageTextContainer from '../components/ImageTextContainer';
import imagesConfig from '../images/site_images/site-image-config';
import martyImage from '../images/Marty-Headshot.jpg';
import brianImage from '../images/Brian-Headshot.jpg';

function importAll(r: any) {
  return r.keys().map(r);
}

const images = importAll(require.context('../images/site_images/home1/', false, /\.(png|jpe?g|svg)$/));

const home1Images = imagesConfig.home1.map((image, index) => ({
  ...image,
  src: images[index].default,
}));

// interface itemDataProps {
//   img: string,
//   title: string,
//   subtitle: string,
// }

// const itemData: itemDataProps[] = [
//   {
//     img: image,
//     title: 'Lancaster',
//     subtitle: 'Performance engineering',
//   },
//   {
//     img: image,
//     title: 'Lancaster II',
//     subtitle: 'Performance envelop',
//   },
//   {
//     img: image,
//     title: 'Lancaster III',
//     subtitle: 'Performance construction',
//   },
//   {
//     img: image,
//     title: 'Lancaster IV',
//     subtitle: 'Performance construction',
//   },
//   {
//     img: image,
//     title: 'Lancaster V',
//     subtitle: 'Performance envelop',
//   },
//   {
//     img: image,
//     title: 'Lancaster VI',
//     subtitle: 'Performance engineering',
//   },
// ];

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 200,
    paddingBottom: 200,
  },
}));

const useAvatarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  large: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
}));

const Home = (): ReactElement => {
  const classes = useStyles();
  const avatarClasses = useAvatarStyles();
  return (
    <>
      <Section mlogoRight background="lightGradiant">
        <Container classes={{ root: classes.root }}>
          <Typography variant="h2">MOT&#362;S, Latin for movement.</Typography>
          <Box maxWidth="50%">
            <Typography variant="body1">
              {/* eslint-disable-next-line max-len */}
              Environmental, social and economic needs are constantly moving. The built environment must respond - MOT&#362;S is our response, the confluence of Building Enclosure, Structural and Construction Engineering.
              {' '}
              <Link to="/why-motus" component={MuiLink}>Read more...</Link>
            </Typography>
          </Box>
        </Container>
      </Section>
      <Section background="white">
        <ImageTextContainer
          heading="Why MOT&#362;S?"
          body={[
            // eslint-disable-next-line max-len
            <>MOT&#362;S, moving from one state to another. From idea to concept to completed project.</>,
            'Do we move efficiently?  Do we make the correct moves? Are we moving in the right direction?',
            'As we see changing environmental awareness and the current energy transition that also affects the built environment, finding answers to these questions is most important.',
            // eslint-disable-next-line max-len
            <>MOT&#362;S Consult Ltd is a growing team of experienced professionals who answer these questions. We are coordinated, focused and controlled to respond to the future of our industry. How we move is incredibly important!</>,
          ]}
          images={home1Images}
          to="/why-motus"
          buttonLocation="text"
          minHeight={600}
        />
      </Section>
      <Section background="dark">
        <Container maxWidth="sm">
          <Typography variant="h3" align="center">Our Services</Typography>
          <Box my={4}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid container item xs alignItems="center" direction="column">
                <Settings fontSize="large" />
                <Line />
                <Box>
                  <Typography variant="h6">Performance Structures</Typography>
                </Box>
              </Grid>
              <Grid container item xs alignItems="center" direction="column">
                <Apartment fontSize="large" />
                <Line />
                <Box>
                  <Typography variant="h6">Performance Enclosures</Typography>
                </Box>
              </Grid>
              <Grid container item xs alignItems="center" direction="column">
                <Build fontSize="large" />
                <Line />
                <Box>
                  <Typography variant="h6">Performance Construction</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="center">
            <Link
              component={Button}
              to="/services"
            >
              Read more
            </Link>
          </Box>
        </Container>
      </Section>
      <Section background="light">
        <Container maxWidth="sm">
          <Typography variant="h3" align="center">Our Team</Typography>
          <Box my={4}>
            <Grid container spacing={10} alignItems="center" justifyContent="center">
              <Grid container item xs alignItems="center" direction="column">
                <Avatar
                  src={brianImage}
                  classes={{ root: avatarClasses.root }}
                  className={avatarClasses.large}
                />
                <Box mt={2}>
                  <Typography variant="h5">Brian Breukelman</Typography>
                </Box>
              </Grid>
              <Grid container item xs alignItems="center" direction="column">
                <Avatar
                  src={martyImage}
                  classes={{ root: avatarClasses.root }}
                  className={avatarClasses.large}
                />
                <Box mt={2}>
                  <Typography variant="h5">Marty Deemter</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="center">
            <Link
              component={Button}
              to="/team"
            >
              Read more
            </Link>
          </Box>
        </Container>
      </Section>
      {/* <Section> */}
      {/*  <Container> */}
      {/*    <Typography variant="h3">Our Projects</Typography> */}
      {/*  </Container> */}
      {/*  <ImageList cols={3} rowHeight={300}> */}
      {/*    {itemData.map((item) => ( */}
      {/*      <ImageListItem key={item.img}> */}
      {/*        <img src={item.img} alt={item.title} /> */}
      {/*        <ImageListItemBar */}
      {/*          title={item.title} */}
      {/*          subtitle={( */}
      {/*            <span> */}
      {/*              {item.subtitle} */}
      {/*            </span> */}
      {/*          )} */}
      {/*        /> */}
      {/*      </ImageListItem> */}
      {/*    ))} */}
      {/*  </ImageList> */}
      {/*  <Container> */}
      {/*    <Box display="flex" justifyContent="center"> */}
      {/*      <Link */}
      {/*        component={Button} */}
      {/*        to="/services" */}
      {/*      > */}
      {/*        More projects */}
      {/*      </Link> */}
      {/*    </Box> */}
      {/*  </Container> */}
      {/* </Section> */}
    </>
  );
};

export default Home;
