import React, { ReactElement, useState } from 'react';
import {
  createStyles, makeStyles, Typography,
} from '@material-ui/core';
import pageImage from '../images/services-image.jpg';
import Section from '../components/Section';
import ImageTextContainer from '../components/ImageTextContainer';
import Container from '../components/Container';
import imagesConfig from '../images/site_images/site-image-config';
import { CarouselContext } from '../contexts/contexts';

function importAll(r: any) {
  return r.keys().map(r);
}

const pEimages = importAll(require.context('../images/site_images/performanceEnvelopes/', false, /\.(png|jpe?g|svg)$/));

const performanceEnvelopesImages = imagesConfig.performanceEnvelopes.map((image) => ({
  ...image,
  src: pEimages.find((img: any) => img.default.includes(image.fileName)).default,
}));

const pSimages = importAll(require.context('../images/site_images/performanceStructure/', false, /\.(png|jpe?g|svg)$/));

const performanceStructuresImages = imagesConfig.performanceStructures.map((image) => ({
  ...image,
  src: pSimages.find((img: any) => img.default.includes(image.fileName)).default,
}));

const pCimages = importAll(require.context('../images/site_images/performanceConstruction/', false, /\.(png|jpe?g|svg)$/));

const performanceConstructionImages = imagesConfig.performanceConstruction.map((image) => ({
  ...image,
  src: pCimages.find((img: any) => img.default.includes(image.fileName)).default,
}));

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 175,
    paddingBottom: 175,
    color: '#fff',
  },
}));

const Services = (): ReactElement => {
  const [autoPlay, setAutoPlay] = useState(true);
  const classes = useStyles();
  return (
    <CarouselContext.Provider
      value={{ autoPlay, setAutoPlay }}
    >
      <Section
        background="darkPrimary"
        mlogoWhite
        mlogoRight
        image={{ src: pageImage, alt: 'image' }}
      >
        <Container classes={{ root: classes.root }}>
          <Typography variant="h2">Our Services</Typography>
        </Container>
      </Section>
      <Section background="white">
        <ImageTextContainer
          body={[
            'While we\'re happy to work with traditional steel, concrete and wood designs on conventional projects, we love the challenges of going beyond the curve in the road.  Our experience with advanced engineering on a wide range of projects has given us the ability to confidently provide these value-add services.',
          ]}
          list={[
            'Alternate Solutions [Beyond Prescriptive Code]',
            'Advanced Structural Design',
            'Advanced Wind-Loading/Snow-Loading Analyses',
            'Structural Peer Reviews',
            'Wind & Snow Loading Peer Reviews',
            'Structural Optimizations',
            'Building/Structure Vibration Measurement/Assessment',
            'Photovoltaic Systems (PV) Analysis & Design – Ground Mount or Roof Mount',
            'Damping System Consulting',
            'Damping System Inspection Services ',
          ]}
          anchor="structures"
          heading="Performance Structures"
          images={performanceStructuresImages}
          showIndicators
        />
      </Section>
      <Section background="lightGradiant" mlogoRight>
        <ImageTextContainer
          body={[
            'Developing high performing enclosures requires the confluence of a wide variety of technical skills and a deep understanding of how things are built.  Equally important is keeping up with product development as it evolves. It is with this mindset, that we offer our services in these areas:',
          ]}
          list={[
            'Multi-Unit Residential Building (MURB) Envelope Restorations',
            'Commercial Building Envelope Restorations',
            'Historic Restoration',
            'Building Envelope Condition Assessments',
            'Façade Design',
            'Building Enclosure Engineering',
            'Green Roof Code Compliance – Wind Uplift & Full System Design',
          ]}
          heading="Performance Enclosures"
          anchor="enclosures"
          imageRight
          images={performanceEnvelopesImages}
          showIndicators
        />
      </Section>
      <Section background="white">
        <ImageTextContainer
          body={[
            'This is where the \'rubber\' hits the road. The ideal scenario is when we meet during preliminary design development. Our experience provides you with the tools to have us act as your representative for any project you take on. Our professional designations allow us to act as prime consultant to provide Value Engineering before and during construction.',
          ]}
          list={[
            'Construction Engineering/Constructability Reviews',
            'Temporary Works Design',
            'Structure Reinforcement Reviews',
            'Structure Demolition Reviews & Design',
            'Owners Representative (For Contracting Restoration Projects)',
          ]}
          anchor="construction"
          heading="Performance construction"
          images={performanceConstructionImages}
          showIndicators
        />
      </Section>
    </CarouselContext.Provider>
  );
};

export default Services;
