import { ImageType } from '../../interfaces';

interface ConfigProps {
  [name: string]: ImageType[]
}

const imagesConfig: ConfigProps = {
  home1: [
    {},
  ],
  performanceEnvelopes: [
    {
      fileName: 'Concord-plaza-wp',
      title: 'Plaza Waterproofing',
      details: 'The Concord Pacific Eau Claire building in downtown Calgary required durable roof systems design and field review services.',
      credit: 'This project was led by Marty Deemter, P.Eng., while at another firm.',
      alt: 'Plaza Waterproofing',
    },
    {
      fileName: 'Gresham-1',
      title: 'Historic Façade Stabilization',
      details: 'The Gresham Building in the historic Inglewood district in Calgary had an assessment of original historic configuration, design and construction of new cladding, including structural attachment, performed to brighten up the façade.',
      credit: 'This project was led by Marty Deemter, P.Eng., while at another firm.',
      alt: 'Historic Façade Stabilization',
    },
    {
      fileName: 'Lancaster-Building',
      title: 'Historic Façade Revitalization',
      details: 'The Lancaster Building in downtown Calgary received an historically accurate restoration of all terra cotta and masonry elements of the façade in conjunction with a full buidling revitalization. The Work also included extensive deep cleaning to remove decades of grime from the façade.',
      credit: 'This project was led by Marty Deemter, P.Eng., while at another firm.',
      alt: 'Historic Façade Revitalization',
    },
    {
      fileName: 'Landmark-Gardens-2',
      title: 'Apartment Style Condominium Recladding',
      details: 'The Landmark Gardens Two Condominium complex in North Calgary had a full recladding performed including energy efficiency and structural upgrades. The Work included architectural and engineering design; Stakeholder communication; CA and field services for the complete rehabilitation of the façade.',
      credit: 'This project was led by Marty Deemter, P.Eng., while at another firm.',
      alt: 'Apartment Style Condominium Recladding',
    },
    {
      fileName: 'PEnncross-(4)',
      title: 'Wood frame Balcony Restoration',
      details: 'The Penncross Condominium in Okotoks had a review done of the existing structure in preparation for a painting project. It was discovered that poor initial construction techniques had led to safety concerns. All components were assessed and replacement structure and enclosure components were designed to perfectly tie in with existing cladding and railing elements and existing structure. Full field review and CA services were provided to ascertain that the construction conformed to requirements.',
      credit: 'This project was led by Marty Deemter, P.Eng., while at another firm. ',
      alt: 'Wood frame Balcony Restoration',
    },
    {
      fileName: 'coulson-hotel',
      title: 'Coulson Hotel ',
      details: 'The ground floor façade of this c. 1857 heritage building was failing.  Several major structural modifications were implemented to stabilize the triple brick load bearing structure as well as rebricking of most of the ground floor to restore the façade windows and doors to match the original construction of the building.',
      credit: 'This  project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Coulson Hotel ',
    },
  ],
  performanceStructures: [
    {
      fileName: 'IMG_3160',
      title: 'Riverdale Immigrant Women\'s Centre Solar PV tracker',
      details: 'A unique three-row south facing tracker was designed for the rooftop of an existing building.  The design included developing the necessary bearings and mechanical components to allow the rows to be tracked from east to west each day.  A bonus from installing this systems was the partial shading for the green roof which improved its health.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Riverdale Immigrant Women\'s Centre Solar PV tracker',
    },
    {
      fileName: 'CN6EEC',
      title: 'Tuned Mass Damper Design',
      details: 'The world\'s first architecturally exposed building vibration control device for Taipei 101 as was designed and constructed by a team led by Brian.  In addition to the machine design itself, Brian pioneered advanced dynamic analysis software with his team to verify performance in both wind and earthquakes.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Tuned Mass Damper Design',
    },
    {
      fileName: 'DSCF3481',
      title: 'New York Mixed-Use building',
      details: 'Controlling vibration of tall slender buildings requires innovative strategies.  This building has two nested sloshing water dampers that absorb and dissipate the energy from the wind and provide comfortable environments for the occupants, especially those on the higher floors.  The total mass of water in the system is approximately 720 tons.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'New York Mixed-Use building',
    },
    {
      fileName: 'DSCF3958',
      title: 'Mike O\'Callaghan–Pat Tillman Memorial Bridge',
      details: 'Stability of bridge structures during construction is an important aspect of these projects.  A combination of wind tunnel testing and computer simulations of wind events was required to verify performance of the structure during wind events.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Mike O\'Callaghan–Pat Tillman Memorial Bridge',
    },
    {
      fileName: 'IMG_5388',
      title: 'Sifto Headframe Structural Certification',
      details: 'Every time adjustments are made to the skip that lifts salt to the surface, the impact that this has on the lifting rope, running gear and headframe supporting structure needs to be evaluated.  A detailed finite element model (FEM) of the full headframe concrete and steel was prepared and various skip loading conditions were evaluated.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Sifto Headframe Structural Certification',
    },
    {
      fileName: 'PG81HR',
      title: 'Boeing Delta IV Ground Winds testing',
      details: 'Designing high performance aero-structures requires detailed knowledge of applied forces.  Some of the highest loads a rocket endures is when it is attached to the landing pad and buffeted by horizontal winds.  An extensive program of wind tunnel testing at multiple scales provided the necessary information to the rocket engineering team.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Wood frame Balcony Restoration',
    },
  ],
  performanceConstruction: [
    {
      fileName: 'IMG_4935',
      title: 'Foothills Medical Centre - Power Plant',
      details: 'Installing a new steam let-down system within the existing FMC power plant required careful evaluation of the existing structure to integrate the necessary new structural supports for the piping and valve assemblies.  ',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Foothills Medical Centre - Power Plant',
    },
    {
      fileName: 'IMG_9386',
      title: 'Southwestern Ontario Cottage',
      details: 'A variety of construction methods were utilized on this modern cottage, including insulated concrete forms (ICF), glue-laminated floor and roof structures, glue-laminated curtain wall support and structural insulated panels for the roof.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Southwestern Ontario Cottage',
    },
    {
      fileName: 'P1160035',
      title: 'Mississippi Lake Cottage',
      details: 'This cottage, with its unique roof geometry and highly glazed ground floor required innovative structural design including providing for many complex steel brackets to join the various engineered wood components at oblique angles.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Mississippi Lake Cottage',
    },
    {
      fileName: 'thumbnail_image0000011',
      title: 'Hamilton Heritage Industrial building',
      details: 'Motus was retained to provide construction engineering services for this historic restoration.  A new central stair was required for new tennant requirements - integrating the new \'floating\' structural steel stairs with the existing structure required careful coordination.',
      alt: 'Hamilton Heritage Industrial building',
    },
    {
      fileName: 'IMAG1687',
      title: 'Tobermory Cottage',
      details: 'Large roof overhangs and a significantly cantilevered roof area interrupted by irregular dormers required careful construction detailing of the wood-frame components.  Several meetings with the builder allowed the team to develop a feasible construction approach for this unique cottage.',
      credit: 'This project was led by Brian Breukelman, P.Eng., while at another firm.',
      alt: 'Tobermory Cottage',
    },
  ],
};

export default imagesConfig;
