import React, { ReactElement } from 'react';
import {
  createStyles, makeStyles, Typography,
} from '@material-ui/core';
import Section from '../components/Section';
import image from '../images/team-image.jpg';
import martyImage from '../images/Marty-Headshot.jpg';
import brianImage from '../images/Brian-Headshot.jpg';
import ImageTextContainer from '../components/ImageTextContainer';
import Container from '../components/Container';

const brianResume = `${process.env.PUBLIC_URL}/220105 Brian Breukelman resume.pdf`;
const martyResume = `${process.env.PUBLIC_URL}/220606 Marty Deemter resume.pdf`;

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 175,
    paddingBottom: 175,
    textAlign: 'right',
  },
}));

const useTypeStyles = makeStyles(() => createStyles({
  root: {
    '&:after': {
      marginLeft: 'auto',
    },
  },
}));

const Team = (): ReactElement => {
  const classes = useStyles();
  const typeClasses = useTypeStyles();
  return (
    <>
      <Section
        background="primaryLight"
        mlogoWhite
        mlogoLeft
        image={{ src: image, alt: 'image' }}
        imageLeft
      >
        <Container classes={{ root: classes.root }}>
          <Typography classes={{ root: typeClasses.root }} variant="h2">The MOT&#362;S Team</Typography>
        </Container>
      </Section>
      <Section background="white">
        <ImageTextContainer
          body={[
            'A farm boy from southern Alberta, Brian’s practical approach to life naturally developed into a love of engineering. Beginning in 1994, Brian has worked at various wind engineering firms to understand wind effects on buildings and assisting developers and architects in developing building designs to minimize wind effects. In 2006, he went on to start his own construction and engineering firm which specialized in structural restoration and custom home design, both independently and in collaboration with architects. Moving to Calgary in 2014, Brian returned to classical structural engineering, working with several top engineering firms as a structural engineering and management leader.',
            'With over 30 years of global industry experience, Brian is known for his client-centric focus, collaboration skills and strong technical experience he brings to each project. He repeatedly demonstrates exemplary technical leadership and provides practical solutions throughout all phases of a project.',
          ]}
          // listTitle="Select project experience:"
          // list={[
          //   'Calgary Cancer Centre, Calgary',
          //   'Taipei 101, Taipei, Taiwan',
          //   'Seton High School, Calgary',
          //   'Sony Berlin, Berlin, Germany',
          //   'Delta IV Launch Vehicle - Boeing, Long Beach, California',
          //   'Sifto Headframe Structural Certification - Goderich, Ontario',
          // ]}
          heading="Brian Breukelman"
          buttonLocation="image"
          // to="/team"
          buttonTitle="Full Resume"
          images={[{ src: brianImage, alt: 'image', key: 'image-1' }]}
          href={brianResume}
        />
      </Section>
      <Section background="white">
        <ImageTextContainer
          body={[
            'Marty started his professional career in the mid 1990’s as a journeyman carpenter and small business contracting company owner. As a contractor, Marty has worked on residential and commercial construction projects, in both new construction and restoration projects. Marty’s inquisitiveness into building design progressed into him completing an engineering degree program in 2010. Since then, Marty has combined his construction experience with engineering knowledge to perform evaluation, restoration, design development and construction administration/ field services for roofing, building envelope, and secondary structural elements of new and existing buildings primarily in the greater Calgary area. His understanding of both the construction and engineering side of construction provides invaluable benefit to contractors and owners of all kinds of buildings.',
          ]}
          // listTitle="Select project experience:"
          // list={[
          //   'The Riverfront Condominium, Calgary',
          //   'A.E.Cross School Fenestration Replacement and Building Envelope Upgrades, Calgary',
          //   'Landmark Condominiums Recladding, Calgary',
          //   'Summit Heights Seniors Facility, Calgary',
          //   'Pavilions of Richmond Hill, Calgary',
          //   'Concorde Pacific Eau Claire, Calgary',
          // ]}
          heading="Marty Deemter"
          buttonLocation="image"
          href={martyResume}
          imageRight
          buttonTitle="Full Resume"
          images={[{ src: martyImage, alt: 'image', key: 'image-1' }]}
        />
      </Section>
    </>
  );
};

export default Team;
