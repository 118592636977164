import { Box } from '@material-ui/core';
import React from 'react';

const Line = () => (
  <Box
    my={2}
    width="30px"
    height="1px"
    marginLeft="auto"
    marginRight="auto"
    bgcolor="primary.main"
  />
);

export default Line;
