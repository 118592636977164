import React, { ReactElement } from 'react';
import {
  createStyles, makeStyles, Typography,
} from '@material-ui/core';
import image from '../images/team2.png';
import Section from '../components/Section';
import Container from '../components/Container';
import TextContainer from '../components/TextContainer';

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 200,
    paddingBottom: 200,
    color: '#fff',
  },
}));

const Projects = (): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Section
        background="darkPrimary"
        mlogoWhite
        mlogoRight
        images={[{ src: image, alt: 'image', key: 'image-1' }]}
      >
        <Container classes={{ root: classes.root }}>
          <Typography variant="h2">Projects</Typography>
          <Typography variant="body2">This is a sub heading for the projects page.</Typography>
        </Container>
      </Section>
      <Section background="white">
        <Container>
          <TextContainer
            heading="Coming soon"
            body={
              [{
                body: [{
                  paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                }],
              }]
            }
          />
        </Container>
      </Section>
    </>
  );
};

export default Projects;
