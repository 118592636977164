import React, { Dispatch, SetStateAction } from 'react';

interface CarouselContextProps {
  autoPlay: boolean
  setAutoPlay: Dispatch<SetStateAction<boolean>>
}

export const CarouselContext = React.createContext<CarouselContextProps>({
  autoPlay: true,
  setAutoPlay: () => {},
});

export const deleteMe = [];
