import {
  Box, createStyles, List, ListItem, ListItemText, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { ReactElement } from 'react';
import Button from './Button';
import Container from './Container';

const useParagraphStyles = makeStyles((theme) => createStyles({
  rootRight: {
    paddingLeft: '50%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 'inherit',
    },
  },
  rootLeft: {
    paddingRight: '50%',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 'inherit',
    },
  },
  rootCenter: {
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  quote: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
    },
  },
}));

const useListStyles = makeStyles(() => createStyles({
  root: {
    paddingLeft: 0,
  },
}));

interface ButtonContainerProps {
  to: string,
  title?: string,
}

const ButtonContainer = (
  {
    to,
    title,
  }: ButtonContainerProps,
): ReactElement => (
  <Box display="flex" justifyContent="center" mt={4}>
    <Link
      component={Button}
      to={to}
    >
      {title}
    </Link>
  </Box>
);

ButtonContainer.defaultProps = {
  title: 'Read more',
};

interface TextBody {
  paragraph?: string | React.ReactNode,
  quote?: string,
}

interface TextBlock {
  heading?: string,
  body: TextBody[],
}

interface TextContainerProps {
  heading?: string;
  body: TextBlock[];
  to?: string,
  text?: 'left' | 'right' | 'center',
  list?: string[],
  listTitle?: string,
  buttonTitle?: string,
}

const TextContainer = (
  {
    heading,
    body = [],
    to,
    text,
    list,
    listTitle,
    buttonTitle,
  }: TextContainerProps,
): ReactElement => {
  const listClasses = useListStyles();
  const paragraphClasses = useParagraphStyles();
  return (
    <Container>
      <Box textAlign={text === 'center' ? 'center' : 'justify'}>
        {heading && <Typography variant={text === 'center' ? 'h3' : 'h2'}>{heading}</Typography>}
        {body.map((textBlock) => (
          <>
            {textBlock.heading && <Typography variant="h5">{textBlock.heading}</Typography>}
            {textBlock.body.map((block) => (
              <>
                {block.paragraph && (
                  <Typography
                    variant="body2"
                    align="justify"
                    classes={
                      // eslint-disable-next-line no-nested-ternary
                      text === 'right'
                        ? { root: paragraphClasses.rootRight }
                        : text === 'left'
                          ? { root: paragraphClasses.rootLeft }
                          : { root: paragraphClasses.rootCenter }
                    }
                  >
                    {block.paragraph}
                  </Typography>
                )}
                {block.quote && (
                  <Box className={paragraphClasses.quote}>
                    <Typography
                      variant="subtitle1"
                      align="left"
                    >
                      {`"${block.quote}"`}
                    </Typography>
                  </Box>
                )}
              </>
            ))}
          </>
        ))}
        {list && (
          <Box mt={2}>
            {listTitle && <Typography variant="h5">{listTitle}</Typography>}
            <List dense disablePadding>
              {list.map((item) => (
                <ListItem classes={{ root: listClasses.root }}>
                  <ListItemText
                    primary={item}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {to && <ButtonContainer to={to} title={buttonTitle} />}
      </Box>
    </Container>
  );
};

TextContainer.defaultProps = {
  heading: undefined,
  to: undefined,
  text: 'left',
  list: [],
  listTitle: undefined,
  buttonTitle: 'Read More',
};

export default TextContainer;
