import React, { ReactElement } from 'react';
import {
  createStyles, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import Section from '../components/Section';
import image from '../images/collab-image.jpg';
import Container from '../components/Container';
import TextContainer from '../components/TextContainer';

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 175,
    paddingBottom: 175,
    textAlign: 'right',
  },
}));

const useTypeStyles = makeStyles(() => createStyles({
  root: {
    '&:after': {
      marginLeft: 'auto',
    },
  },
}));

const Collaborate = (): ReactElement => {
  const classes = useStyles();
  const typeClasses = useTypeStyles();
  return (
    <>
      <Section
        background="primaryLight"
        mlogoWhite
        mlogoLeft
        image={{ src: image, alt: 'image' }}
        imageLeft
      >
        <Container classes={{ root: classes.root }}>
          <Typography classes={{ root: typeClasses.root }} variant="h2">Collaborate with us</Typography>
        </Container>
      </Section>
      <Section background="white">
        <Container>
          <TextContainer
            body={
              [{
                body: [{
                  paragraph: 'As we grow we are excited to collaborate with other like minded professionals in Architecture, Engineering, Development and Property Management!',
                }, {
                  paragraph: (
                    <>
                      Contact us
                      {' '}
                      <Link to="contact" component={MuiLink}>here</Link>
                      {' '}
                      to start the conversation about how we can be more successful together!
                    </>
                  ),
                }],
              }]
            }
          />
        </Container>
      </Section>
    </>
  );
};

export default Collaborate;
