import React, { ReactElement } from 'react';
import {
  Box,
  createStyles, makeStyles, Typography,
} from '@material-ui/core';
import { Apartment } from '@material-ui/icons';
import Section from '../components/Section';
import TextContainer from '../components/TextContainer';
import image from '../images/why-image.jpg';
import Container from '../components/Container';
import Line from '../components/Line';

const useStyles = makeStyles(() => createStyles({
  root: {
    paddingTop: 175,
    paddingBottom: 175,
  },
  section: {
    textAlign: 'center',
  },
}));

const useTypeStyles = makeStyles(() => createStyles({
  root: {
    '&:after': {
      // marginLeft: 'auto',
    },
  },
}));

const Why = (): ReactElement => {
  const classes = useStyles();
  const typeClasses = useTypeStyles();
  return (
    <>
      <Section
        background="darkPrimary"
        mlogoWhite
        mlogoRight
        image={{ src: image, alt: 'image' }}
      >
        <Container classes={{ root: classes.root }}>
          <Typography classes={{ root: typeClasses.root }} variant="h2">Welcome, we are MOT&#362;S</Typography>
        </Container>
      </Section>
      <Section background="white">
        <TextContainer
          heading="Why MOT&#362;S?"
          body={[
            {
              body: [
                {
                  paragraph: (
                    <>
                      MOT&#362;S, Latin for movement.
                    </>
                  ),
                },
                {
                  paragraph: 'Are we moving in the right direction, or fast enough?',
                },
                {
                  paragraph: 'Changing needs of our built environment requires our immediate action.',
                },
                {
                  paragraph: (
                    <>
                      {/* eslint-disable-next-line max-len */}
                      In a rapidly developing and specializing world, the confluence of engineering disciplines is increasingly necessary. Our focus at Motus is to bring about this confluence for more resilient, safe, durable and sustainable outcomes.
                    </>
                  ),
                },
              ],
            },
          ]}
        />
      </Section>
      {/* <Section background="light"> */}
      {/*  <TextContainer */}
      {/*    text="center" */}
      {/*    body={[ */}
      {/*      { */}
      {/*        body: [ */}
      {/*          { */}
      {/* eslint-disable-next-line max-len */}
      {/*            paragraph: 'The built environment, though ‘static’, has been developed by an increasingly fragmented consulting industry with increasingly high performance Code/energy requirements. Lack of alignment between stakeholders.', */}
      {/*          }, */}
      {/*        ], */}
      {/*      }, */}
      {/*    ]} */}
      {/*    heading="Key problem" */}
      {/*  /> */}
      {/* </Section> */}
      <Section background="dark" className={classes.section}>
        <Container>
          <Typography variant="body1" align="center">
            To overcome the above we are moving our skills back together.
          </Typography>
          <Typography variant="body1" align="center">
            MOT&#362;S: the CONFLUENCE of Engineering
          </Typography>
          <Box mt={3} display="flex" justifyContent="space-evenly">
            <Box display="flex" alignItems="center" flexDirection="column">
              <Apartment fontSize="large" />
              <Line />
              <Typography variant="h6">Performance Structures</Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Apartment fontSize="large" />
              <Line />
              <Typography variant="h6">Performance Enclosures</Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Apartment fontSize="large" />
              <Line />
              <Typography variant="h6">Performance Construction</Typography>
            </Box>
          </Box>
        </Container>
      </Section>
      <Section background="white">
        <TextContainer
          body={[
            {
              body: [
                {
                  // eslint-disable-next-line max-len
                  paragraph: <>It is in this spirit, MOT&#362;S Consult was founded in 2021.  While the world was in the throes of COVID19, changes in government and unprecedented economic disparity, we are putting our best feet forward to meet the challenges that exist in our built environment.</>,
                },
                {
                  quote: '...we are putting our best feet forward to meet the challenges that exist in our built environment.',
                },
                {
                  paragraph: 'Change has been accelerating in our built environment for several decades. To meet carbon neutral objectives, further acceleration is required. Our codes and practices, which have served us well for generations, are no longer able to keep up. Rapid product development and construction methods are forcing us to look beyond traditional prescriptive codes. Alternate solutions and performance-based design are allowing us to manage the risks in building enclosures, systems and structures.',
                },
                {
                  paragraph: 'We are active in local and international engineering bodies which are developing more performance-based codes and higher performing materials/systems. Integrating these into our built environment is a challenging task and one we\'re excited about participating in.',
                },
              ],
            },
          ]}
          heading="Genesis"
        />
      </Section>
    </>
  );
};

export default Why;
