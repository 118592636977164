import React, { ReactElement } from 'react';
import {
  Avatar,
  Box,
  createStyles, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { Email, Phone } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form/build/module';
import Section from '../components/Section';
import image from '../images/home-image.jpg';
import Container from '../components/Container';
import brianImage from '../images/Brian-Headshot.jpg';
import martyImage from '../images/Marty-Headshot.jpg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingTop: 175,
    paddingBottom: 175,
    // textAlign: 'right',
  },
  contact: {
    marginTop: theme.spacing(4),
  },
}));

const useAvatarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    border: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
  },
  large: {
    height: theme.spacing(10),
    width: theme.spacing(10),
  },
}));

const useTypeStyles = makeStyles(() => createStyles({
  root: {
    '&:after': {
      // marginLeft: 'auto',
    },
  },
}));

const useDetailsStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

const useIconStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontSize: '0.8rem',
    marginRight: theme.spacing(1),
  },
}));

const Contact = (): ReactElement => {
  const classes = useStyles();
  const detailsClasses = useDetailsStyles();
  const typeClasses = useTypeStyles();
  const iconClasses = useIconStyles();
  const avatarClasses = useAvatarStyles();
  const { loaded, error } = useHubspotForm({
    portalId: '9230959',
    formId: '4e208063-1fbc-46af-afde-f5cc7a2b885e',
    target: '#contact-form',
  });
  return (
    <>
      <Section
        background="darkPrimary"
        mlogoWhite
        mlogoRight
        image={{ src: image, alt: 'image' }}
      >
        <Container classes={{ root: classes.root }}>
          <Typography classes={{ root: typeClasses.root }} variant="h2">Contact MOT&#362;S</Typography>
        </Container>
      </Section>
      <Section background="white">
        <Container>
          <Typography variant="h2">Let us know how we can help you</Typography>
          <Grid container classes={{ root: classes.contact }}>
            <Grid item xs={12} md={6}>
              <Box mb={5}>
                <Avatar
                  src={brianImage}
                  classes={{ root: avatarClasses.root }}
                  className={avatarClasses.large}
                />
                <Typography variant="h6">Brian Breukelman</Typography>
                <Box className={detailsClasses.root}>
                  <Phone classes={{ root: iconClasses.root }} />
                  403-919-6203
                </Box>
                <Box className={detailsClasses.root}>
                  <Email classes={{ root: iconClasses.root }} />
                  bbreukelman@motusconsult.ca
                </Box>
              </Box>
              <Box mb={5}>
                <Avatar
                  src={martyImage}
                  classes={{ root: avatarClasses.root }}
                  className={avatarClasses.large}
                />
                <Typography variant="h6">Marty Deemter</Typography>
                <Box className={detailsClasses.root}>
                  <Phone classes={{ root: iconClasses.root }} />
                  403-836-1144
                </Box>
                <Box className={detailsClasses.root}>
                  <Email classes={{ root: iconClasses.root }} />
                  mdeemter@motusconsult.ca
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} spacing={2}>
              {loaded && <div id="contact-form" />}
              {error && <Typography variant="body1">{error}</Typography> }
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
