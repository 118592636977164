import RouteItem from '../interfaces';
import Home from '../pages/Home';
import Team from '../pages/Team';
import Why from '../pages/Why';
import Services from '../pages/Services';
import Contact from '../pages/ContactWrapper';
import Collaborate from '../pages/Collaborate';
import Projects from '../pages/Projects';

export const routes: RouteItem[] = [
  {
    key: 'router-home',
    title: 'Home',
    tooltip: 'Home',
    path: '/',
    enabled: true,
    menuItem: false,
    component: Home,
    appendDivider: true,
    subRoutes: [],
  },
  {
    key: 'router-about',
    title: 'About Us',
    path: '/about',
    tooltip: 'About us',
    enabled: true,
    menuItem: true,
    appendDivider: true,
    menuId: 'about-menu',
    subRoutes: [
      {
        key: 'router-about-why',
        title: 'Why Motus',
        tooltip: 'Why Motus',
        path: '/why-motus',
        enabled: true,
        component: Why,
        appendDivider: true,
      },
      {
        key: 'router-about-team',
        title: 'Our Team',
        tooltip: 'Our Team',
        path: '/team',
        enabled: true,
        component: Team,
        appendDivider: true,
      },
    ],
  },
  {
    key: 'router-services',
    title: 'Services',
    path: '/services',
    tooltip: 'Services',
    enabled: true,
    appendDivider: true,
    menuItem: true,
    menuId: 'services-menu',
    singlePage: true,
    component: Services,
    asLink: true,
    subRoutes: [
      {
        key: 'router-services-structures',
        title: 'Performance Structures',
        tooltip: 'Performance Structures',
        path: { pathname: '/services', hash: '#structures' },
        enabled: true,
        appendDivider: true,
      },
      {
        key: 'router-services-enclosures',
        title: 'Performance Enclosures',
        tooltip: 'Performance Enclosures',
        path: { pathname: '/services', hash: '#enclosures' },
        enabled: true,
        appendDivider: true,
      },
      {
        key: 'router-services-construction',
        title: 'Performance Construction',
        tooltip: 'Performance Construction',
        path: { pathname: '/services', hash: '#construction' },
        enabled: true,
        appendDivider: true,
      },
    ],
  },
  {
    key: 'router-projects',
    title: 'Projects',
    path: '/projects',
    tooltip: 'Projects',
    enabled: false,
    appendDivider: true,
    menuItem: true,
    menuId: 'projects-menu',
    subRoutes: [
      {
        key: 'router-projects-restoration',
        title: 'Restoration',
        tooltip: 'Restoration',
        path: '/projects/restoration',
        enabled: true,
        component: Projects,
        appendDivider: true,
      },
      {
        key: 'router-projects-structural',
        title: 'Structural',
        tooltip: 'Structural',
        path: '/projects/structural',
        enabled: true,
        component: Projects,
        appendDivider: true,
      },
      {
        key: 'router-projects-construction',
        title: 'Construction',
        tooltip: 'Construction',
        path: '/projects/construction',
        enabled: true,
        component: Projects,
        appendDivider: true,
      },
    ],
  },
  {
    key: 'router-collaborate',
    title: 'Collaborate',
    tooltip: 'Collaborate',
    path: '/collaborate',
    enabled: true,
    menuItem: true,
    component: Collaborate,
    appendDivider: true,
    subRouteFooterOnly: true,
    subRoutes: [{
      key: 'router-collaborate-sub',
      title: 'Collaborate with us',
      tooltip: 'Collaborate with us',
      path: { pathname: '/collaborate' },
      enabled: true,
      appendDivider: true,
    }],
  },
  {
    key: 'router-contact',
    title: 'Contact',
    tooltip: 'Contact',
    path: '/contact',
    enabled: true,
    menuItem: true,
    component: Contact,
    appendDivider: true,
    subRouteFooterOnly: true,
    subRoutes: [{
      key: 'router-contact-sub',
      title: '',
      tooltip: 'Contact us',
      path: { pathname: '/contact' },
      enabled: true,
      appendDivider: true,
      nonLink: true,
    }],
  },
];
