import React, { ReactElement, ReactNode } from 'react';
import {
  Box, Button, Container, createStyles, makeStyles,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import Popover from 'material-ui-popup-state/HoverPopover';
import { ReactComponent as Logo } from '../images/logo.svg';
import RouteItem from '../interfaces';
import { routes } from '../config';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme: Theme) => createStyles({
  logo: {
    width: 75,
  },
  menu: {
    borderTop: `solid 2px ${theme.palette.primary.main}`,
    paddingTop: 10,
    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        justifyContent: 'flex-start',
      },
    },
  },
}));

const usePopoverStyles = makeStyles(() => createStyles({
  paper: {
    boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.10)',
  },
}));

interface LayoutProps {
    children: ReactNode,
}

const LogoLink = (
  {
    ...props
  },
): ReactElement => {
  const classes = useStyles();
  return (
    <a {...props}>
      <Logo className={classes.logo} />
    </a>
  );
};

const Layout = (
  {
    children,
  }: LayoutProps,
): ReactElement => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  return (
    <>
      <Header>
        <Container maxWidth="md">
          <Box display="flex" alignItems="center" py={2}>
            <Box flex="1 1">
              <Link to="/" component={LogoLink} />
            </Box>
            <Box display="flex" className={classes.menu}>
              {routes.map((route: RouteItem) => (
                route.enabled && route.menuItem && (
                  <React.Fragment key={route.key}>
                    {route.subRoutes && !route.subRouteFooterOnly && route.subRoutes.length > 0 ? (
                      <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                          <div>
                            {route.asLink ? (
                              <Link
                                component={Button}
                                to={route.path}
                                aria-haspopup="true"
                                aria-controls={`${route.title}-menu`}
                                {...bindHover(popupState)}
                                {...{
                                  variant: 'text',
                                  size: 'small',
                                }}
                                key={route.key}
                              >
                                {route.title}
                              </Link>
                            ) : (
                              <Button
                                aria-haspopup="true"
                                aria-controls="about-menu"
                                variant="text"
                                {...bindHover(popupState)}
                                size="small"
                              >
                                {route.title}
                              </Button>
                            )}
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              classes={{ paper: popoverClasses.paper }}
                            >
                              <Box display="flex" flexDirection="column" p={1}>
                                {route.subRoutes && route.subRoutes.map((subRoute: RouteItem) => (
                                  <Link
                                    component={Button}
                                    to={subRoute.path}
                                    {...{
                                      variant: 'text',
                                      size: 'small',
                                    }}
                                    key={subRoute.key}
                                  >
                                    {subRoute.title}
                                  </Link>
                                ))}
                              </Box>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    ) : (
                      <Link
                        component={Button}
                        to={route.path}
                        {...{
                          variant: 'text',
                          size: 'small',
                        }}
                        key={route.key}
                      >
                        {route.title}
                      </Link>
                    )}
                  </React.Fragment>
                )
              ))}
            </Box>
          </Box>
        </Container>
      </Header>
      <>
        {children}
      </>
      <Footer />
    </>
  );
};

export default Layout;
