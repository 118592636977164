import {
  Container as MuiContainer, createStyles, makeStyles, StyledComponentProps,
} from '@material-ui/core';
import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'relative',
    zIndex: 2,
    '@media (min-width: 600px)': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

interface ContainerProps extends StyledComponentProps, ComponentProps<any> {
  children: ReactNode,
  classes?: any,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
}

const Container = (
  {
    children,
    classes,
    maxWidth,
    disableGutters = false,
  }: ContainerProps,
):ReactElement => {
  const baseClasses = useStyles();
  return (
    <MuiContainer
      maxWidth={maxWidth}
      classes={{ root: baseClasses.root, ...classes }}
      disableGutters={disableGutters}
    >
      <>
        {children}
      </>
    </MuiContainer>
  );
};

Container.defaultProps = {
  maxWidth: 'md',
  classes: undefined,
};

export default Container;
