import React, { ReactElement } from 'react';
import {
  createStyles, makeStyles, Button as MuiButton, ButtonProps as MuiButtonProps,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main} , ${theme.palette.primary.main})`,
    color: '#fff',
    minWidth: 125,
  },
}));

interface ButtonProps extends MuiButtonProps {}

const Button = (
  {
    ...props
  }: ButtonProps,
): ReactElement => {
  const classes = useStyles();
  return (
    <MuiButton classes={{ root: classes.root }} {...props} />
  );
};

export default Button;
