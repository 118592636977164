import { createTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    primary: {
      main: '#34C1D6',
    },
    secondary: {
      main: '#7EC894',
    },
  },
  typography: {
    h2: {
      fontSize: '2rem',
      '&:after': {
        content: '" "',
        display: 'block',
        marginTop: 10,
        marginBottom: 10,
        width: 50,
        height: 1,
        backgroundColor: '#34C1D6',
      },
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
      textAlign: 'center',
      '&:after': {
        content: '" "',
        display: 'block',
        marginTop: 10,
        marginBottom: 10,
        width: 50,
        height: 1,
        margin: '0 auto',
        backgroundColor: '#34C1D6',
      },
    },
    h5: {
      fontSize: '1.1rem',
    },
    h6: {
      fontSize: '0.8rem',
    },
    subtitle1: {
      fontSize: '2.5rem',
      fontWeight: 300,
      lineHeight: 1.2,
    },
    subtitle2: {
      textTransform: 'uppercase',
      fontSize: '0.8rem',
    },
    body2: {
      marginBottom: 15,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    caption: {
      fontSize: '0.62rem',
    },
  },
  dark: {
    main: '#2A2B2C',
  },
} as const;

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key]
}

declare module '@material-ui/core/styles/createTheme' {
  // eslint-disable-next-line no-unused-vars
  interface Theme extends CustomTheme { }
  // eslint-disable-next-line no-unused-vars
  interface ThemeOptions extends CustomTheme { }
}

export default createTheme(theme);
